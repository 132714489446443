import React, { useState } from 'react';
import Table from '../components/Table';
import data from '../data/Authenticate.json';
import { FaRegCopy } from 'react-icons/fa';

const Authenticate = () => {
  const [copiedRequest, setCopiedRequest] = useState(false);
  const [copiedResponse, setCopiedResponse] = useState(false);

  const copyToClipboard = (code, type) => {
    navigator.clipboard.writeText(code);

    if (type === 'request') {
      setCopiedRequest(true);
      setTimeout(() => setCopiedRequest(false), 2000);
    } else if (type === 'response') {
      setCopiedResponse(true);
      setTimeout(() => setCopiedResponse(false), 2000);
    }
  };

  const curlCall = 'curl -X POST https://{casino-url}/authenticate \\\n' +
      '-H "Content-Type: application/json" \\\n' +
      '-H "hash: 9d6c0f3829bb8784751677977aef32a3bfe3be4bdcbba2368c9fc5252ff50dcc" \\\n' +
      '-H "generic-id: 46b0da0cd81423dcdac17d2070b4af16" \\\n' +
      '-d \'{\n' +
      '  "playerAccessToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9",\n' +
      '  "currencyCode": "USD"\n' +
      '}\'\n';

  const responseCode = `{
  "balance": "double",
  "bonusBalance": "double",
  "status": "string",
  "code": "integer",
  "currency": "string"
}`;

  return (
    <div className="row mt-3">
      <div className="col-lg-7">
        <h1 className="mb-4">Authenticate</h1>
        <p>This endpoint is used for authenticating player token and to get player balance</p>
        <pre className="bg-light p-3 border rounded">
          POST https://&#123;casino-url&#125;/authenticate
        </pre>
        <h6 className="mt-4 mb-1">Body Fields</h6>
        <Table tableData={data.request}/>
        <h6 className="mt-4 mb-1">Response Fields</h6>
        <Table tableData={data.response}/>
      </div>
      <div className="col-lg-5 col-xl-4 ps-xxl-5 mt-5 position-relative">
        <h6 className="mt-4">Example</h6>
        <div className="card shadow-sm mb-3" style={{backgroundColor: '#f8f9fa', borderRadius: '5px'}}>
          <div className="card-header bg-light d-flex justify-content-between align-items-center">
            <span>Shell/cURL:</span>
            <button
              className="btn btn-sm btn-light"
              onClick={() => copyToClipboard(curlCall, 'request')}
              title="Copy to clipboard"
            >
              <FaRegCopy/>
            </button>
          </div>

          {copiedRequest && <span className="text-success position-absolute top-0 end-0 mt-2 me-5">Copied!</span>}

          <pre style={{ margin: '0', padding: '15px', backgroundColor: '#f5f5f5', borderRadius: '5px' }}>
            <code style={{ color: 'green' }}>
              {curlCall}
            </code>
          </pre>
        </div>
        <div className="card shadow-sm" style={{ backgroundColor: '#f8f9fa', borderRadius: '5px' }}>
          <div className="card-header bg-light d-flex justify-content-between align-items-center">
            <span>Response</span>
            <button
              className="btn btn-sm btn-light"
              onClick={() => copyToClipboard(responseCode, 'response')}
              title="Copy to clipboard"
            >
              <FaRegCopy/>
            </button>
          </div>

          {copiedResponse && <span className="text-success position-absolute top-0 end-0 mt-2 me-5">Copied!</span>}

          <pre style={{ margin: '0', padding: '15px', backgroundColor: '#f5f5f5', borderRadius: '5px' }}>
            <code style={{ color: 'green' }}>
              {responseCode}
            </code>
          </pre>
        </div>
      </div>
    </div>
  );
};

export default Authenticate;
