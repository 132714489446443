import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  return (
    <nav className="sidebar">
      <h4>GETTING STARTED</h4>
      <Link to="/">Basic terminology</Link>
      <Link to="/lunching_game">Launching game</Link>
      <Link to="/calculating_hash">Calculating hash</Link>
      <h4>API REFERENCE</h4>
      <Link to="/api_integrations">Api Integrations</Link>
      <Link to="/authenticate">Authenticate</Link>
      <Link to="/debit">Debit</Link>
      <Link to="/credit">Credit</Link>
      <Link to="/rollback">Rollback</Link>
      <h4>ERRORS</h4>
      <Link to="/codes">Codes</Link>
    </nav>
  );
};

export default Sidebar;
