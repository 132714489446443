import React, { useState } from 'react';
import { FaRegCopy } from 'react-icons/fa';

const CalculatingHash = () => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    const codeBlock = `
json = {
  customer: "2020120400005",
  token: "D8ECE868830CE30D467D0D715414D81D3685A0368FD7CBE257FBAB6599D6BE8CD2D8777328F804FB",
  gameId: "Test_game",
  amount: "2",
  currency: "TRY",
  betId: 5334875,
  trxId: 22198,
  freespin: {
    freespinRef: "23a234de532"
  }
}

jsonString = "{\"customer\":\"2020120400005\",\"token\":\"D8ECE868830CE30D467D0D715414D81D3685A0368FD7CBE257FBAB6599D6BE8CD2D8777328F804FB\",\"gameId\":\"Test_game\",\"amount\":\"2\",\"currency\":\"TRY\",\"betId\":5334875,\"trxId\":22198,\"freespin\":{\"freespinRef\":\"23a234de532\"}}"

toHashString = "{\"customer\":\"2020120400005\",\"token\":\"D8ECE868830CE30D467D0D715414D81D3685A0368FD7CBE257FBAB6599D6BE8CD2D8777328F804FB\",\"gameId\":\"Test_game\",\"amount\":\"2\",\"currency\":\"TRY\",\"betId\":5334875,\"trxId\":22198,\"freespin\":{\"freespinRef\":\"23a234de532\"}}55554525de8e724703abe221caef9c2e"

hash = 3aca48a20d7ec29080b90d82912e65e97e19287615e33f46016bded2a8776fb7

// JS Example code
var genericSecretKey = "55554525de8e724703abe221caef9c2e";
var jsonString = JSON.stringify(json);
var toHash = jsonString + genericSecretKey;
var hash = CryptoJS.SHA256(toHash).toString();
    `;
    navigator.clipboard.writeText(codeBlock);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="row mt-3">
      <div className="col-lg-7">
        <h1 className="mb-4">Calculating hash</h1>
        <p>
          Hash value will be calculated using SHA-256. We will have exchange secretKey, that is used in the calculation.
          <br />
          Note: hash field is case sensitive and should contain only lowercase letters and numbers. Hash should be calculated by marshaling the request body JSON, concatenating genericSecretKey, and applying SHA-256 over the marshaled string.
        </p>
      </div>

      <div className="col-lg-5 col-xl-4 ps-xxl-5 mt-5 position-relative">
      <div className="card shadow-sm" style={{ backgroundColor: '#f8f9fa', borderRadius: '5px' }}>
          <div className="card-header bg-light d-flex justify-content-between align-items-center">
            <span></span>
            <button
              className="btn btn-sm btn-light"
              onClick={copyToClipboard}
              title="Copy to clipboard"
            >
              <FaRegCopy />
            </button>
          </div>

          {copied && <span className="text-success position-absolute top-0 end-0 mt-2 me-5">Copied!</span>}

          <pre style={{ margin: '0', padding: '15px', backgroundColor: '#f5f5f5', borderRadius: '5px' }}>
          <span className="mb-4" style={{ fontSize: 'small', color: 'gray' }}>JavaScript</span> <br />
          <span className="mb-4">Steps</span>
          <br />
          <code style={{ color: 'green' }}>
              {`
json = {
  customer: "2020120400005",
  token: "D8ECE868830CE30D467D0D715414D81D3685A0368FD7CBE257FBAB6599D6BE8CD2D8777328F804FB",
  gameId: "Test_game",
  amount: "2",
  currency: "TRY",
  betId: 5334875,
  trxId: 22198,
  freespin: {
    freespinRef: "23a234de532"
  }
}

jsonString = "{\"customer\":\"2020120400005\",\"token\":\"D8ECE868830CE30D467D0D715414D81D3685A0368FD7CBE257FBAB6599D6BE8CD2D8777328F804FB\",\"gameId\":\"Test_game\",\"amount\":\"2\",\"currency\":\"TRY\",\"betId\":5334875,\"trxId\":22198,\"freespin\":{\"freespinRef\":\"23a234de532\"}}"

toHashString = "{\"customer\":\"2020120400005\",\"token\":\"D8ECE868830CE30D467D0D715414D81D3685A0368FD7CBE257FBAB6599D6BE8CD2D8777328F804FB\",\"gameId\":\"Test_game\",\"amount\":\"2\",\"currency\":\"TRY\",\"betId\":5334875,\"trxId\":22198,\"freespin\":{\"freespinRef\":\"23a234de532\"}}55554525de8e724703abe221caef9c2e"

hash = 3aca48a20d7ec29080b90d82912e65e97e19287615e33f46016bded2a8776fb7

// JS Example code
var genericSecretKey = "55554525de8e724703abe221caef9c2e";
var jsonString = JSON.stringify(json);
var toHash = jsonString + genericSecretKey;
var hash = CryptoJS.SHA256(toHash).toString();
              `}
            </code>
          </pre>
        </div>
      </div>
    </div>
  );
};

export default CalculatingHash;