import React from 'react';
import Table from '../components/Table';
import data from '../data/Code.json';

const LunchingGame = () => {
  return (
    <>
      <div className="container-text-table mt-3">
        <h1 className="mb-4">Error code</h1>
        <p>You can raise these errors from your end and we will take action based on that if there is any error code you will use we will mark it as failed.</p>
        <Table tableData={data} />
        <p>In case of a bet placing debit call we will reject the bet,</p>
        <p>in case of credit or rollback we will mark that transaction as failed but the bet will be successful and will call the operator periodically every 20 seconds with the same bet to resolve the bet.</p>
        <p>If there is any failed transaction the player will not be able to place a new bet so its the operator's responsibility to log any error and resolve the same bet with the provider.</p>
      </div>
    </>
  );
};

export default LunchingGame;