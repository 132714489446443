import React from 'react';

const BasicTerminology = () => {
  return (
    <div className="mt-3">
      <div className="container-text-table">
        <h1 className="mb-4">Basic terminology</h1>
        <p>This section provides essential definitions.</p>

        <h4 className="mt-4">Player access token</h4>
        <p>A player access token (token) is created and passed as a query parameter on the launch game URL. This will be required to verify the operator player.</p>

        <h4 className="mt-4">Operator</h4>
        <p>Customer’s project that integrates games by API.</p>

        <h4 className="mt-4">Generic-id</h4>
        <p>Unique id of the game provider which is agreed upon by both parties.</p>

        <h4 className="mt-4">Hash</h4>
        <p>Hashed value that needs to be verified and sent during API calls.</p>
      </div>
    </div>
  );
};

export default BasicTerminology;