import React from 'react';

const Header = () => {
  return (
    <header className="header">
      <h1 className='text-white'>API Documentation</h1>
    </header>
  );
};

export default Header;
