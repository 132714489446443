import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import BasicTerminology from './pages/BasicTerminology';
import LunchingGame from './pages/LunchingGame';
import CalculatingHash from './pages/CalculatingHash';
import ApiIntegrations from './pages/ApiIntegrations';
import Authenticate from './pages/Authenticate';
import Credit from './pages/Credit';
import Debit from './pages/Debit';
import Rollback from './pages/Rollback';
import Codes from './pages/Codes';
import './App.css';

function App() {
  return (
    <Router>
      <div className="containerCss">
        <Header />
        <div class="row g-0">
          <div className="col-md-3 col-xl-2">
            <Sidebar className="sidebar" />
          </div>
          <div className="col-md-9 col-xl-10 p-3 gx-0">
            <Routes>
              <Route path="/" element={<BasicTerminology />} />
              <Route path="/lunching_game" element={<LunchingGame />} />
              <Route path="/calculating_hash" element={<CalculatingHash />} />
              <Route path="/api_integrations" element={<ApiIntegrations />} />
              <Route path="/authenticate" element={<Authenticate />} />
              <Route path="/credit" element={<Credit />} />
              <Route path="/debit" element={<Debit />} />
              <Route path="/rollback" element={<Rollback />} />
              <Route path="/codes" element={<Codes />} />
            </Routes>
          </div>
        </div>
        <Footer />
      </div>
    </Router >
  );
}

export default App;