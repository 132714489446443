import React, { useState } from 'react';
import Table from '../components/Table';
import data from '../data/Credit.json';
import { FaRegCopy } from 'react-icons/fa';

const Credit = () => {
  const [copiedRequest, setCopiedRequest] = useState(false);
  const [copiedHeaders, setCopiedHeaders] = useState(false);
  const [copiedResponse, setCopiedResponse] = useState(false);

  const copyToClipboard = (text, type) => {
    navigator.clipboard.writeText(text);
    if (type === 'request') {
      setCopiedRequest(true);
      setTimeout(() => setCopiedRequest(false), 2000);
    } else if (type === 'headers') {
      setCopiedHeaders(true);
      setTimeout(() => setCopiedHeaders(false), 2000);
    } else if (type === 'response') {
      setCopiedResponse(true);
      setTimeout(() => setCopiedResponse(false), 2000);
    }
  };

  const curlCall = 'curl -X POST https://{casino-url}/credit \\\n' +
      '-H "hash: 9d6c0f3829bb8784751677977aef32a3bfe3be4bdcbba2368c9fc5252ff50dcc" \\\n' +
      '-H "Generic-Id: 46b0da0cd81423dcdac17d2070b4af16" \\\n' +
      '-H "Content-Type: application/json" \\\n' +
      '-d \'{\n' +
      '  "customer": "2019105152683",\n' +
      '  "token": "692A5DF3996581ADD825BEB52A71F54F519AEEBCB2BA0DBCC3D440C2B98887A076D037C7F879D344",\n' +
      '  "gameId": "dummy_record",\n' +
      '  "amount": 2,\n' +
      '  "currency": "TRY",\n' +
      '  "betId": "b3",\n' +
      '  "trxId": "d1"\n' +
      '}\'';

  const requestText = `{
  "customer": "2019105152683",
  "token": "692A5DF3996581ADD825BEB52A71F54F519AEEBCB2BA0DBCC3D440C2B98887A076D037C7F879D344",
  "gameId": "dummy_record",
  "amount": 2,
  "currency": "TRY",
  "betId": "b3",
  "trxId": "d1"
}`;

  const headersText = `{
  "hash": "9d6c0f3829bb8784751677977aef32a3bfe3be4bdcbba2368c9fc5252ff50dcc",
  "Generic-Id": "46b0da0cd81423dcdac17d2070b4af16"
}`;

  const responseText = `{
  "balance": "188.8",
  "bonusBalance": "3.1",
  "status": "SUCCESS",
  "code": 0,
  "currency": "TRY"
}`;

  return (
    <div className="mt-3">
      <div className="row">
        <div className="col-lg-7">
          <h1 className="mb-4">Credit</h1>
          <p>This endpoint is used in combination with /debit and /rollback. It increases the player balance for the
            received amount.</p>
          <pre className="bg-light p-3 border rounded">
            POST https://&#123;casino-url&#125;/credit
          </pre>
          <h6 className="mt-4 mb-1">Request Fields</h6>
          <Table tableData={data.request}/>
          <h6 className="mt-2 mb-1">Headers</h6>
          <Table tableData={data.headers}/>
          <h6 className="mt-4 mb-1">Response Fields</h6>
          <Table tableData={data.response}/>
          <h6 className="mt-4">Example</h6>
        </div>

        <div className="col-lg-5 col-xl-4 ps-xxl-5 mt-5 position-relative">
          <div className="card shadow-sm mb-3" style={{ backgroundColor: '#f8f9fa', borderRadius: '5px' }}>
            <div className="card-header bg-light d-flex justify-content-between align-items-center">
              <span>Shell/cURL:</span>
              <button
                className="btn btn-sm btn-light"
                onClick={() => copyToClipboard(curlCall, 'request')}
                title="Copy to clipboard"
              >
                <FaRegCopy />
              </button>
            </div>
            {copiedRequest && <span className="text-success position-absolute top-0 end-0 mt-2 me-5">Copied!</span>}
            <pre style={{ margin: '0', padding: '15px', backgroundColor: '#f5f5f5', borderRadius: '5px' }}>
              <span className="mb-4" style={{ fontSize: 'small', color: 'gray' }}>Unset</span>
              <br />
              <code style={{ color: 'green' }}>{curlCall}</code>
            </pre>
          </div>

          {/*<div className="card shadow-sm mb-3" style={{ backgroundColor: '#f8f9fa', borderRadius: '5px' }}>*/}
          {/*  <div className="card-header bg-light d-flex justify-content-between align-items-center">*/}
          {/*    <span>headers:</span>*/}
          {/*    <button*/}
          {/*      className="btn btn-sm btn-light"*/}
          {/*      onClick={() => copyToClipboard(headersText, 'headers')}*/}
          {/*      title="Copy to clipboard"*/}
          {/*    >*/}
          {/*      <FaRegCopy />*/}
          {/*    </button>*/}
          {/*  </div>*/}
          {/*  {copiedHeaders && <span className="text-success position-absolute top-0 end-0 mt-2 me-5">Copied!</span>}*/}
          {/*  <pre style={{ margin: '0', padding: '15px', backgroundColor: '#f5f5f5', borderRadius: '5px' }}>*/}
          {/*    <span className="mb-4" style={{ fontSize: 'small', color: 'gray' }}>Unset</span>*/}
          {/*    <br />*/}
          {/*    <code style={{ color: 'green' }}>{headersText}</code>*/}
          {/*  </pre>*/}
          {/*</div>*/}

          <div className="card shadow-sm mb-3" style={{ backgroundColor: '#f8f9fa', borderRadius: '5px' }}>
            <div className="card-header bg-light d-flex justify-content-between align-items-center">
              <span>Response:</span>
              <button
                className="btn btn-sm btn-light"
                onClick={() => copyToClipboard(responseText, 'response')}
                title="Copy to clipboard"
              >
                <FaRegCopy />
              </button>
            </div>
            {copiedResponse && <span className="text-success position-absolute top-0 end-0 mt-2 me-5">Copied!</span>}
            <pre style={{ margin: '0', padding: '15px', backgroundColor: '#f5f5f5', borderRadius: '5px' }}>
              <span className="mb-4" style={{ fontSize: 'small', color: 'gray' }}>Unset</span>
              <br />
              <code style={{ color: 'green' }}>{responseText}</code>
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Credit;
