import React, { useState } from 'react';
import Table from '../components/Table';
import data from '../data/ApiIntegration.json';
import { FaRegCopy } from 'react-icons/fa';

const ApiIntegrations = () => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    const codeBlock = `{
  "balance": "Double",
  "bonusBalance": "Double",
  "status": "string",
  "code": "integer",
  "currency": "string"
}`;
    navigator.clipboard.writeText(codeBlock);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="row mt-3">
      <div className="col-lg-7">
        <h1 className="mb-4">API Integrations</h1>
        <p>
          All API calls need to send back balance, bonusBalance, status, code, currency need to send 0 as code in response always in case of any other error code will mark the transaction as failed.
          <br />Below provided response object needs to be practiced before sending responses.
        </p>
        <p>GameId will be sent as <code>'AceGaming-100'</code> (for crash Game).</p>
        <p className="mt-2 mb-4">Response Schema</p>
        <Table tableData={data} />
      </div>

      <div className="col-lg-5 col-xl-4 ps-xxl-5 mt-5 position-relative">
        <div className="card shadow-sm" style={{ backgroundColor: '#f8f9fa', borderRadius: '5px' }}>
          <div className="card-header bg-light d-flex justify-content-between align-items-center">
            <span></span>
            <button
              className="btn btn-sm btn-light"
              onClick={copyToClipboard}
              title="Copy to clipboard"
            >
              <FaRegCopy />
            </button>
          </div>

          {copied && <span className="text-success position-absolute top-0 end-0 mt-2 me-5">Copied!</span>}

          <pre style={{ margin: '0', padding: '15px', backgroundColor: '#f5f5f5', borderRadius: '5px' }}>
          <span className="mb-4" style={{ fontSize: 'small', color: 'gray' }}>Unset</span>
          <br />
          <code style={{ color: 'green' }}>
              {`{
  "balance": "Double",
  "bonusBalance": "Double",
  "status": "string",
  "code": "integer",
  "currency": "string"
}`}
            </code>
          </pre>
        </div>
      </div>
    </div>
  );
};

export default ApiIntegrations;