import React, { useState } from 'react';
import Table from '../components/Table';
import data from '../data/LaunchingGame.json';
import { FaRegCopy } from 'react-icons/fa';

const LunchingGame = () => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const launchGameUrl = `https://{casino-url}/crash?organizationId=1&trader=1&currency=USD&customer=1&token=38215b2648ed30537a461e5d039da2f515ff8740&language=en`;

  return (
    <div className="container-text-table mt-3">
      <h1 className="mb-4">Launching game</h1>
      <h4 className="mt-4">Launch real game</h4>
      <p>In order to launch a game, the operator must call the LaunchGame request from Iframe or directly. It will automatically load the game for you.</p>
      <p>So you will have to pass the URL you provide with query parameters listed below:</p>
      <h5 className="mt-4">Launch game example</h5>
      <div className="position-relative">
        <pre className="bg-light p-3 border rounded" style={{ margin: '0' }}>
          {launchGameUrl}
        </pre>
        <button
          className="btn btn-sm btn-light position-absolute top-0 end-0 m-2"
          onClick={() => copyToClipboard(launchGameUrl)}
          title="Copy to clipboard"
        >
          <FaRegCopy />
        </button>
        {copied && (
          <span className="text-success position-absolute" style={{ top: '50%', right: '45px', transform: 'translateY(-50%)' }}>
            Copied!
          </span>
        )}
      </div>
      <h5 className="mt-4">Query parameters</h5>
      <Table tableData={data} />
    </div>
  );
};

export default LunchingGame;
